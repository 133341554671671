<template>
  <div class="setting-item s-show-date-by-default">
    <esmp-switch
      v-model="localSetting.value"
      shape="circular"
      :label="localSetting.name"
    />
    <esmp-datepicker-adaptive
      v-if="localSetting.value"
      v-model="defaultDate"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'SShowDateByDefault',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'localSetting.value': function (val) {
      if (!val) {
        this.defaultDate = null;
      }
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultDate: {
      get() {
        return this.localSetting.childs[0]?.value ? new Date(this.localSetting.childs[0].value) : null;
      },
      set(v) {
        this.localSetting.childs[0].value = v ? dayjs(v).format() : null;
      },
    },
  },
};
</script>
